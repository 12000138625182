.ag-theme-alpine .ag-root-wrapper{
    /* box-shadow: 0px 8px 10px 0px #00000012; */
    /* padding:20px; */
    width: 100%;
    border:none;
}

.grid-container{
    background-color: #FFFFFF;
    padding: 0.4%;
    border-radius: 0.5rem;
   }

.ag-body-viewport{
    overflow-y: auto !important;
    scrollbar-width: 0; 
}
.ag-body-viewport::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
/* .ag-body-viewport:hover{
    overflow-y: auto !important;
  
} */

.ag-header-cell-text{
    font-family: "Barlow";
    font-style: 'Regular';
    font-weight: 400;
    font-size: 1rem;
    color: #707070;
    /* line-height:100%; */
    
}

.ag-cell-value, .ag-group-value {
    font-family: "Barlow";
    font-style: 'Regular';
    font-weight: 400;
    font-size: 0.9rem;
    color: #3E4954;
    /* line-height:100%; */
}
.ag-theme-alpine .ag-header-row{
    background-color: #FFFFFF;
}

.ag-cell-label-container{ 
    
  display: flex;
  justify-content: space-around; 
  align-items: center;
  /* width:100% !important; */
 
}

/* .ag-input-field-input {
   min-width:28px;
} */

.ag-theme-alpine .ag-ltr .ag-cell{
    color:#3E4954;
    font-family:"Barlow";
    font-weight: 500;
    font-style: normal;
    /* margin-top:15px; */
    /* padding-bottom: 100%; */
}

/* .ag-theme-alpine .ag-icon-menu{
    display: none;
} */