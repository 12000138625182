

.field-label{
    font-size:1rem;
    --fontSize:1rem;

}

@media only screen and (min-width:768px) {

}

@media only screen and (min-width:1024px) {}

@media only screen and (min-width:1366px) {}

@media only screen and (min-width:1920px) {}

@media only screen and (min-width:2560px) {}