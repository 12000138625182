.lineChart {
    width: 50%;
    float: left;
    background: rgba(0,0,0,0.7);
}
.lineChart-tooltip {
    position: absolute;
    text-align: center;
    color:#1FA7D3;
    padding: 1%;
    background: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 5px;
    pointer-events: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1);
    opacity:0

  }
  
/* 
.tooltip {
    background:rgb(111, 172, 224);
    border-radius: 2px;
    box-shadow: var(--shadow);
    color: white;
    padding: 1%;
    pointer-events: none;
    position: absolute;
    border:0.5px solid rgb(127, 175, 247);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1rem;
    /* opacity: 0; */
*/
span {
    color: var(--black);
    display: block;
    font-size: 10px;
}


.MuiTableCell-body {
    padding: 4px 0 !important;
    padding-left: 1.5% !important;
}

/* CSS */
.tooltip {
    position: absolute;
    background:#FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1);
    color: #1FA7D3;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    display: none; /* Hide by default */
  }