/* ./App.css */

/* Reset some default styles */
body, h1, h2, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Set up a simple layout for the app */
.app {
  display: flex;
  flex-direction: column;
  height:100vh;

}

.app-header {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 5%;
}

.app-body {
  display: flex;
  flex: 1;
}

.app-sidebar {
  background-color: #555;
  color: white;
  padding: 5% 2%;
  width: 20%;
}

.app-sidebar ul {
  list-style: none;
}

.app-sidebar li {
  cursor: pointer;
  padding: 2% 1%;
  transition: background-color 0.3s;
}

.app-sidebar li:hover {
  background-color: #777;
}

.app-main {
  flex: 1;
  /* padding: 2% 5%; */
}

/* Customize additional styles as needed */
.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.dropdown {
  margin-right: 2%;
}

.profile {
  font-size: 1rem;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .app-body {
    flex-direction: column;
  }

  .app-sidebar {
    width: 100%;
  }
}

