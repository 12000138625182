.pin {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: green;
    transform-origin: bottom;
    cursor: pointer;
    animation: pulse 1.5s ease infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(4);
      opacity: 0.5;
    }
  }
  
  .map-chart-container{
    width: 100%;
    box-sizing: border-box;
    border-radius: 2%;
    background-color: #ffffff;
    /* border: solid 2px red; */
    margin: 2% 4% 2% 4%;
    padding: 2% 4% 2% 4%;
}


.gradient-text {
  font-family: 'Barlow';
  font-size: 12px;
  fill: rgb(29, 7, 109);
  /* Add more styles as needed */
}

.main-container {
  position: relative;
  flex: 1;
  width: 100%;
}

.fullscreen-button {
  position: absolute;
  cursor: pointer;
  z-index: 0; /* Set z-index to 0 */
}

.map-container {
  height: 90%;
  width: 100%;
  position: relative;
}

.tooltip {
  position: absolute;
  pointer-events: none;
  background-color: white;
  padding: 0.5rem;
  color: blue;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  z-index: 1;
}
.fullscreen-tooltip {
  /* Define styles for the tooltip in fullscreen mode */
  background-color: blue;
  color: white;
  position:relative;
  /* Add any other styles you want to apply to the tooltip in fullscreen mode */
}
