rect.disabled {
    fill: transparent !important;
  }

  /* legend */
.legend {
    font-size: 14px;
  }

  .chart-container {
    width: 100%;
    max-width: 400px; /* Set a maximum width as needed */
    background-color: white; /* Set the background color to white */
    border-radius: 8px; /* Add rounded corners if desired */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a shadow for a card-like appearance */
    margin: 0 auto; /* Center the container horizontally */
  }
  
  
  .chart-container svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px; /* Adjust margin as needed */
  }

  .legend-container {
    border:1px soid red;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 300px; /* Set a maximum height */
    /* Add any additional styles as needed */
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px; /* Adjust margin as needed */
  }
  
  .legend-color {
    width: 16px;
    height: 16px;
    margin-right: 8px; /* Adjust margin as needed */
  }
  
  .legend-label {
    font-size: 14px;
  }
  
  .pie-tooltip {
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    color:black;
    /* Additional styling as needed */
  }

  /* In your CSS file */
.legend-item.strikethrough div {
  text-decoration: line-through;
  background-color:transparent;
}
