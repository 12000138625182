/* Header.css */

.app-header {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    background-color: #ffffff !important;
    padding: 0.8% 1% 0.8% 1%;
    box-shadow: none !important;
  }
  
  .logo-menu-container {
    display: flex;
    align-items: center;
    width: 20%;
    padding: 0% 1% 0% 1%;

  }
  
  .logo {
    margin-right: 1%; /* Responsive margin */
  }
  
  .dropdown {
    display: flex;
    justify-content:center;
    /* width: 60%; */
    /* background-color: red; */
    /* border: solid 1px black; */
    flex-grow: 1;
  }
  
  .profile {
    margin-left: auto; /* Push to the right */
    /* border: solid 1px black; */
  }
  
  .menu-button {
    display: none; /* Hide initially for larger screens */
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 767px) {
  
    .logo-menu-container{
      margin-right: 2.5%;
    }
    .menu-button {
      display: block; /* Show for smaller screens */
      margin-top: 2%; /* Responsive margin */
    }
  
    .dropdown {
      margin: 0/* Responsive margin */
    }
  }
  
  /* .MuiOutlinedInput-input{
    border: 1px solid red !important;
    display: flex;
    ali
    padding: 0;
  } */
  /* .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input */
  .sample{
    border: 1px solid red !important;
    /* padding: 0 !important; */
    color: pink !important;

  }