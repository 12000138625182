.app-main {
    /* flex: 1; */
    /* padding: 1rem; */
    padding: 0%;
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 80px); 
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Add a smooth transition for background color and box-shadow change */
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: transparent transparent; /* Firefox */
  }


.content-box{             
    width: 95%;
    height:100%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* border:"10px solid red"; */
    overflow-y:auto;
   
  }
 
    .app-main:hover {
      /* Show scrollbar on hover */
      scrollbar-color: auto auto; /* Firefox */
    }
    
    /* Hide scrollbar in Webkit browsers (Chrome, Safari) and Blink browsers (Opera) */
    .app-main::-webkit-scrollbar,
    .content-box::-webkit-scrollbar{
      width: 1px;
    }
    
    .app-main::-webkit-scrollbar-thumb,
    .content-box::-webkit-scrollbar  {
      background-color: transparent;
    }
    
    .app-main::-webkit-scrollbar-track,
    .content-box::-webkit-scrollbar  {
      background-color: transparent;
    }
    
    .app-main:hover::-webkit-scrollbar-thumb,
    .content-box::-webkit-scrollbar  {
      background-color: auto;
    }
    
    .app-main:hover::-webkit-scrollbar-track,
    .content-box::-webkit-scrollbar  {
      background-color: auto;
    }
    
    
    .app-main h1 {
      color: #333;
    }
    
    .app-main p {
      margin-bottom: 0.5rem;
    }
    
    
  /* Default styles for all screen sizes */
  .IconImage {
    width: 22px;
    height: 22px;
  }
  
  /* Media query for screens smaller than 600px */
  @media (max-width: 600px) {
    .IconImage {
      width: 25px;
      height: 25px;
    }
  }
  
  /* Media query for screens between 601px and 900px */
  @media (min-width: 601px) and (max-width: 900px) {
    .IconImage {
      width: 28px;
      height: 28px;
    }
  }
  
  /* Media query for screens larger than 901px */
  @media (min-width: 901px) {
    .IconImage {
      width: 32px;
      height: 32px;
    }
  }
  /* Media query for screens larger than 901px */
  @media (min-width: 1400px) {
    .IconImage {
      width: 36px;
      height: 36px;
    }
  }